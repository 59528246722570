import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// images
import banner_img from "../images/icons/edi.svg";
import online_icon from "../images/attest_online.png";
import auto_icon from "../images/attest_auto.png";
import flow_icon from "../images/attest_flow.png";
import overview_icon from "../images/attest_overview.png";
import edi_img from "../images/edi_swe.svg";

const BOT_CODE = "kxFV5wCG";

function Edi() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>EDI | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Send and/or receive EDI messages." />
                <meta name="keywords" content="edi | EDI flow" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Send and/or receive EDI messages." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/edi" />
                <link rel="cannonical" href="https://www.crediflow.com/edi" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">EDI</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        We offer a comprehensive solution for EDI 
                                        regardless of whether you want to send or receive EDI messages.
                                        <br/><br/>
                                        It can e.g. mean that you receive EDI orders and send 
                                        back order replies, delivery notices and invoices
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-1/2 sm:1/3 p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="Send and receive EDI" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">edi</span>
                    </div>
                    <div className="grid md:grid-cols-4 gap-8">
                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={online_icon} alt="EDI flow"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">EDI flow fully integrated into your system.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={auto_icon} alt="Automate manual handling."/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Automate manual handling.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={overview_icon} alt="Simpler overview with EDI"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Simpler overview of several companies.</p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#page_article">
                            <div className="h-full bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={flow_icon} alt="Manage several companies with EDI flow"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">Manage several companies with different business systems in one and the same flow.</p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-36 pb-28" id="page_article">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100 pb-32" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-start">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Complete solution for your EDI flow</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                No double registration on any external website is needed.
                                <br/><br/>
                                The service also works in the same way for flows from your suppliers if you want it.
                                <br/><br/>
                                This includes us setting up the EDI communication 
                                with all the parties that are relevant to you, 
                                mapping and conversion as well as ongoing distribution.
                            </p>
                            <Link to="/demo" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>
                
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={edi_img} alt="send e-invoice" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Edi;